// @TODO split to multiple components
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Typography from '@material-ui/core/Typography/Typography';
import styled from 'styled-components';
import { mq } from '../utils/helpers';
import CookieConsent from 'react-cookie-consent';

import {
  Layout,
  HeroSection
} from '../containers';
import {
  Container,
  FooterAddressSocialIconsContainer, FooterCopyrightWrapper,
  GradientBackground,
  Paragraph,
  StyledButton,
} from '../styled';
import { SocialIcon } from '../components';

class ContactPage extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data } = this.props;
    const { allMarkdownRemark } = data;
    const { edges } = allMarkdownRemark;
    const { contact } = edges.filter(el => el.node.frontmatter.contact)[0].node.frontmatter;
    const {
      contact_address_heading,
      contact_address_email,
      contact_address_street,
      contact_address_city,
      contact_address_website,
      contact_button,
      contact_social_heading,
      contact_copyright_informations,
      contact_cookies_link,
      contact_cookies_information,
      contact_gdpr_link,
      contact_gdpr_informations,
    } = contact;

    return (
      <Layout>
        <HeroSection>
        <div style={{
          paddingBottom: '3.75rem', paddingTop: '2.75rem', alignItems: 'center',
        }}
        >
          <Container style={{display: 'block'}}>
            <ContainerBox>
              <ContactHeroText>
              Привет!
              </ContactHeroText>
                <a className="link-contact"
                   href={`mailto:kontakt@linkvisuals.pl`}
                   style={{
                       display: 'block',
                       textDecoration: 'none',
                   }}
                ><p style={{
                    fontSize: '1.625rem',
                    color: 'white',
                    fontWeight:'600',
                    textDecoration: 'none',
                }}>kontakt@linkvisuals.pl
                </p>

                </a>

                <div style={{display: 'flex', justifyContent: 'space-between', flexWrap:'wrap', marginTop:'7rem'}}>

                    <div style={{flex: '1 0 25%'}}>
                        <p style={{ fontSize: '18px', fontWeight: '600', marginBottom:'1rem' }}>Stanisław Komar</p>
                        <p style={{ fontSize: '16px', fontWeight: '300' }}>
                            <a className="link-contact"
                               href={`tel:+48533633991`}
                               style={{
                                   display: 'block',
                                   textDecoration: 'none',
                               }}
                            >
                                +48&nbsp;533&nbsp;633&nbsp;991
                            </a>
                            <a className="link-contact"
                               href={`mailto:stanislaw.komar@linkvisuals.pl`}
                               style={{
                                   display: 'block',
                                   textDecoration: 'none',
                               }}
                            >
                                stanislaw.komar@linkvisuals.pl
                            </a>
                        </p>
                    </div>

                    <div style={{flex: '1 0 25%'}}>
                        <p style={{ fontSize: '18px', fontWeight: '600', marginBottom:'1rem' }}>Ula Nazarko-Mrugacz</p>
                        <p style={{ fontSize: '16px', fontWeight: '300' }}>
                            <a className="link-contact"
                               href={`tel:+48603214465`}
                               style={{
                                   display: 'block',
                                   textDecoration: 'none',
                               }}
                            >
                                +48&nbsp;603&nbsp;214&nbsp;465
                            </a>
                            <a className="link-contact"
                               href={`mailto:ula.nazarko@linkvisuals.pl`}
                               style={{
                                   display: 'block',
                                   textDecoration: 'none',
                               }}
                            >
                                ula.nazarko@linkvisuals.pl
                            </a>
                        </p>
                    </div>

                    <div style={{flex: '1 0 26%'}}>
                        <p style={{ fontSize: '18px', fontWeight: '600', marginBottom:'1rem' }}>Agnieszka Siebauer-Osman</p>
                        <p style={{ fontSize: '16px', fontWeight: '300' }}>
                            <a  className="link-contact"
                                href={`tel:+48533633391`}
                                style={{
                                    display: 'block',
                                    textDecoration: 'none',
                                }}
                            >
                                +48&nbsp;533&nbsp;633&nbsp;391
                            </a>
                            <a  className="link-contact"
                                href={`mailto:agnieszka.osman@linkvisuals.pl`}
                                style={{
                                    display: 'block',
                                    textDecoration: 'none',
                                }}
                            >
                                agnieszka.osman@linkvisuals.pl
                            </a>
                        </p>
                    </div>

                </div>

              <div style={{display:'block'}}>
                <hr style={{borderTop:'1px solid #ffffff', height:0}}/>
              </div>
                <ContactDetailsContainer>

                    <LargeContactColumn>
                        <p style={{
                            fontSize: '16px',
                            color: 'white',
                            textDecoration: 'none',
                        }}>Link Visuals
                            <span style={{display:'block'}}>Langiewicza 16/1</span>
                            <span style={{display:'block'}}>02-071 Варшава, Польша</span>
                        </p>
                    </LargeContactColumn>
                    <div  style={{ flex: '1 0 25%'  }}>
                        <Paragraph  style={{
                            fontSize: '16px',
                            color: 'white',
                            textDecoration: 'none',
                        }} marginBottom="0.1rem">
                            <a className="link-contact" target="_blank" href='https://www.instagram.com/linkvisuals/'>
                                <SocialLink>Instagram</SocialLink>
                            </a>
                            <a className="link-contact" target="_blank" href='https://www.facebook.com/linkvisuals/'>
                                <SocialLink>Facebook</SocialLink>
                            </a>
                            <a className="link-contact" target="_blank" href='https://vimeo.com/linkvisuals'>
                                <SocialLink>Vimeo</SocialLink>
                            </a>
                        </Paragraph>
                    </div>
                    <div  style={{ flex: '1 0 50%'  }}>
                        <Paragraph className="text-sm-right"  style={{
                            fontSize: '16px',
                            color: 'white',
                            textDecoration: 'none',
                        }} marginBottom="0.1rem">
                            <a className="link-contact" target="_blank" href='https://www.behance.net/linkvisuals'>
                                <SocialLink>Behance</SocialLink>
                            </a>
                            <a className="link-contact" target="_blank" href='https://www.linkedin.com/company/link-visuals-sp.-z-o.o./'>
                                <SocialLink>Linkedin</SocialLink>
                            </a>
                        </Paragraph>
                    </div>

                </ContactDetailsContainer>

              <FooterCopyrightWrapper style={{textAlign:'left'}}>
                  <p>Wysyłając do nas email, potwierdzasz, że znana Ci jest nasza polityka prywatności, w której
                      informujemy Cię o szczegółach przetwarzania Twoich danych osobowych oraz Twoich prawach z tym
                      związanych.</p>
                <Paragraph style={{textAlign: 'right'}} marginBottom="0">{contact_copyright_informations} <Link to="/cookie-policy">{contact_cookies_information}</Link></Paragraph>
              </FooterCopyrightWrapper>
            </ContainerBox>
          </Container>
        </div>
        </HeroSection>
          <CookieConsent
              location="bottom"
              buttonText="Rozumiem"
              containerClasses='container-cookie'
              buttonClasses='container-cookie-btn'

              contentClasses='container-cookie-content'
              contentStyle={{margin: '.1rem 0 0 0', background:'#000000'}}
              declineButtonText="Decline"
              style={{background:'#000000', border:''}}
              buttonStyle={{ color: '#ffffff', background:'linear-gradient(90deg,#0043ff -90%,#00071a 80%)', fontSize: '14px', border:'1px solid #0043FF', margin: '0' }}
              cookieName="gatsby-gdpr-google-analytics">
              Ta strona przechowuje i ma dostęp do cookies w celu prawidłowego funkcjonowania, analiz i&nbsp;statystyk strony. Dalsze korzystanie ze strony, bez zmiany ustawień w&nbsp;Twojej przeglądarce
              internetowej, oznacza, że zgadzasz się na ich użycie. Więcej informacji znajdziesz w <Link style={{color: '#ffffff', textDecoration:'underline'}} to='/cookie-policy'>Polityce prywatności</Link>.
          </CookieConsent>
      </Layout>
    );
  }
}

export default ContactPage;

const LargeContactColumn = styled.div`
  flex: 1 0 25%;
  font-weight: bold;
  
  ${mq.mq960`
    flex: 1 0 100%; 
  `}
`;

const ContactDetailsContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between; 
  flex-wrap: wrap;
  
  ${mq.mq960`
    margin-top: 5rem;
    flex: 1 0 100%; 
  `}
`;

const ContactHeroText = styled.h1`
  margin-top: 10rem;
  font-size: 80px;
  font-weight: 600;
  font-family: 'Galano Grotesque Light','Helvetica','Segoe UI','Helvetica Neue','Arial',sans-serif;
  font-weight: normal;
  display:block;
  
  ${mq.mq960`
    margin-top: 2rem;
  `}
`;

const SocialLink = styled.span`
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  
  &:hover {
      color: #FF0146;
  }
`;

const ContainerBox = styled.div`
    align-items: flex-start;
`;

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_button
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          }
        }
      }
    }
  }
`;
